<template>
  <div
    id="ProductDetails"
    v-if="true"
    v-loading="loading"
    style="min-height: 100%"
  >
    <div>
      <HomePageTop ref="pagetop"></HomePageTop>
      <Dialog ref="dialog" :config="config">
        <!-- <span>插槽内容</span> -->
      </Dialog>
      <div class="goodsMain" v-if="details">
        <div class="nav flex acenter">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
            <el-breadcrumb-item v-if="$route.query.LP">
              <div class="navSpan">
                <span
                  class="text-overflow-one"
                  :title="$route.query.LP"
                  @click="$router.go(-1)"
                >
                  {{ $route.query.LP }}</span
                >
              </div>
              <!-- <span @click="$router.go(-1)" class="cur">
            {{ $route.query.goodsName }}</span
          > -->
            </el-breadcrumb-item>
            <el-breadcrumb-item class="shopNameItem">
              <div class="navSpan">
                <span class="text-overflow-one" :title="details.titleT">
                  {{ details.titleT }}</span
                >
              </div>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="goodsCon">
          <pictureFrame></pictureFrame>
          <div>
            <!-- 商品价格等 -->
            <productIntroduction></productIntroduction>
            <div>
              <div class="kind">
                <div
                  v-for="(skuItem, skuindex) in skuProps"
                  :key="skuindex"
                  :class="
                    skuProps.length - 1 != skuindex ? 'newKindCon' : 'kindCon'
                  "
                >
                  <div
                    class="conRightTitle"
                    style="min-width: 100px"
                    :title="skuItem.keyT"
                  >
                    {{ $fun.trunUpperCase(skuItem.keyT) }}：
                  </div>
                  <div
                    class="ConBody"
                    id="chioseGoods"
                    v-if="skuProps.length - 1 != skuindex"
                  >
                    <div class="controlButton" @mouseover="goLeft">
                      <i class="el-icon-arrow-left"></i>
                    </div>
                    <div
                      class="sizeItem"
                      v-for="(item, index) in skuItem.valueT"
                      :key="index"
                      :class="{ choiceColor: skuItem.active == index }"
                      @click="getColor(skuItem, index)"
                    >
                      <img :src="item.picUrl" alt="" v-if="item.picUrl" />
                      <!--                          <img v-if="item.picUrl" :src="item.picUrl" alt="" />-->
                      {{ item.name }}
                    </div>
                    <div
                      class="controlButton"
                      @mouseover="goRight(true)"
                      @mouseout="goRight(false)"
                    >
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </div>

                  <!-- 多选择商品 -->
                  <div class="allCheck" v-else>
                    <div class="allHead">
                      <div class="title" style="justify-content: center">
                        {{ $fanyi("产品") }}
                      </div>
                      <div class="price">{{ $fanyi("单价") }}</div>
                      <div class="numGroup">{{ $fanyi("在库数") }}</div>
                      <div class="num">{{ $fanyi("数量") }}</div>
                    </div>
                    <div
                      :style="{ maxHeight: addShow ? '262px' : '10000px' }"
                      class="list"
                    >
                      <div
                        v-for="(item, index) in goodsInventoryOption"
                        :key="index"
                      >
                        <div class="item">
                          <!-- 属性 -->
                          <div
                            class="title"
                            :class="{
                              center: !item.picUrl && item.nameT.length < 30,
                            }"
                          >
                            <el-popover placement="right" trigger="hover">
                              <img
                                :src="item.picUrl"
                                alt=""
                                style="width: 300px; height: 300px"
                              />
                              <img
                                :src="item.picUrl"
                                alt=""
                                v-if="item.picUrl"
                                @click="getPic(item.picUrl)"
                                slot="reference"
                              />
                            </el-popover>

                            <el-popover
                              placement="bottom"
                              width="177"
                              trigger="hover"
                            >
                              <span
                                class="allDetailName"
                                style="text-align: center; display: block"
                              >
                                {{ item.nameT }}
                              </span>
                              <span
                                slot="reference"
                                :class="{
                                  maxLine: item.nameT.length > 30,
                                }"
                              >
                                {{ item.nameT }}
                              </span>
                            </el-popover>
                          </div>
                          <div class="price">
                            <div>
                              <b
                                >{{
                                  $fun.ceil(
                                    item.price * $store.state.exchangeRate
                                  )
                                }}€</b
                              >
                            </div>
                            <div>({{ item.price }}￥)</div>
                          </div>
                          <!-- 库存 -->
                          <div class="numGroup">
                            <!-- 库存为0的时候后端会将库存转化为999999,前台没有库存的商品库存显示0,但是可以选择数量下单 -->
                            {{
                              item.amountOnSale == 999999
                                ? 0
                                : item.amountOnSale
                            }}
                            {{ $fanyi("件") }}
                          </div>
                          <!-- 选择数量 -->
                          <div class="num">
                            <el-input-number
                              v-model.number="item.num"
                              :step="1"
                              :max="item.amountOnSale"
                              :min="0"
                              size="small"
                              @change="maxNum(item, index)"
                            >
                            </el-input-number>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 下拉 -->
                    <div
                      class="addShow"
                      @click="addShow = !addShow"
                      v-if="showNum > 4"
                      :class="{ top: !addShow }"
                    >
                      <span>{{ $fanyi("部署更多") }}</span>
                      <img
                        class="jiahaoIcon"
                        v-if="addShow"
                        :src="require('@/assets/icon/jiahao_fill.svg')"
                      />
                      <img
                        class="jiahaoIcon"
                        v-else
                        :src="require('@/assets/icon/jianhao.svg')"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="operatingParameter">
            <operatingParameter></operatingParameter>
          </div>
        </div>

        <shopDetail ref="ShopDetail" v-if="shopDetail" />

        <div class="goodsDetail">
          <h4>{{ $fanyi("商品属性") }}</h4>
          <div class="property">
            <p v-for="(item, index) in details.goodsInfo.detail" :key="index">
              <span :title="item.keyT">
                {{ $fun.trunUpperCase(item.keyT) }}:</span
              >
              <span :title="item.valueT">{{ item.valueT }}</span>
            </p>
          </div>
          <h4 style="margin-top: 60px">{{ $fanyi("商品详情") }}</h4>
          <div class="detail">
            <div v-html="details.description"></div>
          </div>
        </div>
      </div>
      <div v-else class="notHaveGoods" v-show="!loading">
        <div class="hintInfo">
          <img src="../../assets/newImg/default.png" alt="" />
          <p>
            {{
              $fanyi("该商品因库存不足或其他原因已经找不到，请查看其他商品！")
            }}
          </p>
          <p>
            <button class="backHomePage" @click="$fun.routerToPage('/')">
              {{ $fanyi("返回首页") }}
            </button>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Dialog from "@/components/public/Dialog";
import pictureFrame from "./components/pictureFrame.vue";
import productIntroduction from "./components/productIntroduction.vue";
import operatingParameter from "./components/operatingParameter.vue";
import Foot from "@/components/foot/Foot";
import HomePageTop from "@/components/head/HomePageTop.vue";
import shopDetail from "./components/shopDetail.vue";
export default {
  metaInfo: {
    title: "RAKUMARTラクマート",
  },
  data() {
    return {
      wangwang: "",
      shopNameTrue: "",
      addShow: true,
      showNum: 0, // 显示选择条数
      isAddCart: false, //动画显示时使用
      isClick: false, //点击时使用
      token: localStorage.getItem("user_token"),
      datas: {
        goods_id: this.$route.query.goods_id,
        shop_type: "1688",
      },
      loading: true,
      shopLoading: true,
      details: null, //详情
      shopDetail: null, //商店推荐商品
      goodsInfo: null, //商品属性
      detailsImg: "", // 主图
      detailsImgIndex: 0, // 图片索引
      isColorShow: false, // 是否显示颜色图 true显示图，false显示尺寸css
      isSizeShow: false, //
      num: 1, // 数量
      price: 0, //价格
      max: 1, //
      skuProps: {}, //所有规格数据
      skuPropsChinese: {}, //所有规格数据中文
      addCartSkuList: [],
      money: 0,
      Count: 0, // 总数
      skuId: "",
      specId: "",
      // 选中的商品类别
      goodsStr: "",
      error: false,
      // 临时清单数组
      TemporaryListing: [],
      goodsInventoryStr: {},
      config: {
        top: "20vh",
        width: "525px",
        title: this.$fanyi("提示"),
        btnTxt: [this.$fanyi("确定")],
      },
      goodsInventoryOption: [],
      goodsInventoryPriceOption: [],
      goodsFavoriteStatus: false, //商品是否已收藏
      shopFavoriteStatus: false, //店铺是否已收藏
      goods_inventory: "", //商品的所有属性选项，添加购物车用
      forbid: false, //禁止点击
      showType: "img", // 大图显示视频还是图片
    };
  },
  components: {
    Foot,
    pictureFrame,
    HomePageTop,
    Dialog,
    productIntroduction,
    operatingParameter,
    shopDetail,
  },
  created() {
    this.productDetails();
  },
  mounted() {},
  methods: {
    // 获取商品推荐商品
    async getShopRecommendedGoods() {
      await this.$api
        .getShopRecommendedGoods({
          shopName: this.wangwang,
          shopType: 1688,
        })
        .then((res) => {
          this.shopLoading = false;
          if (res.code != 0) return;
          let lack = 7 - (res.data.goods.length % 7); //取得店铺商品数离7个的倍数差多少个商品的数量
          //差多少个就从最前面取多少个填充到最后
          for (let i = 0; i < lack; i++) {
            res.data.goods.push(res.data.goods[i]);
          }
          // 将商品详情的商品分成7个一组
          res.data.goods = this.$fun.changeArr(res.data.goods, 7);
          this.shopDetail = res.data;
        });
    },
    // 查看商品是否已收藏
    favoriteGoodsCheckIn() {
      this.$api
        .favoriteGoodsCheckIn({
          goods_id: JSON.stringify([this.$route.query.goods_id]),
        })
        .then((res) => {
          if (res.code != 0) return false;
          this.goodsFavoriteStatus = res.data[0].inFavorite;
        });
    },

    //切换展示的内容
    lookVideo() {
      this.showType = "video";
    },
    goLeft() {
      let timer = setInterval(function() {
        let osTop = document.querySelector("#chioseGoods").scrollLeft;
        let ispeed = Math.floor(-osTop / 15);
        document.querySelector(
          "#chioseGoods"
        ).scrollLeft = document.querySelector("#chioseGoods").scrollLeft =
          osTop + ispeed;

        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 10);
    },
    goRight(type) {
      if (type) {
        this.timerCon = setInterval(function() {
          document.querySelector("#chioseGoods").scrollLeft += 10;
        }, 10);
      } else {
        clearInterval(this.timerCon);
      }
    },

    // 获取商品详情
    productDetails() {
      this.datas.outMemberId = this.$fun.setUserID();
      this.$api.goodsParticularsV2(this.datas).then((res) => {
        this.loading = false;
        if (res.code != 0 || !res.data.goodsInfo) return (this.details = null);
        if (res.data.video) {
          this.showType = "video";
        }
        this.dataProcessing2(res);
        this.details = res.data;
        this.goodsInfo = res.data.goodsInfo;
        this.wangwang = res.data.shopName;
        document.title = "Rakumart-" + res.data.titleT.substr(0, 50); //由于订单详情数据是跳转页面后再获取的，所以不能在路由跳转的时候修改

        this.getShopRecommendedGoods();
        this.dataProcessing();
        this.favoriteGoodsCheckIn(res.data);
        this.getsaleShopName(res);
        this.favoriteShopCheckIn();
      });
    },
    // 获取店铺名
    getsaleShopName(res) {
      this.$api
        .saleShopName({
          goods_id: this.$route.query.goods_id,
          shop_type: res.data.fromPlatform,
          wangwang: this.wangwang,
        })
        .then((res) => {
          if (res.data != "") {
            this.shopNameTrue = res.data;
            this.$forceUpdate();
          }
        });
    },
    // 查看商品是否已收藏
    favoriteShopCheckIn() {
      this.$api
        .favoriteShopCheckIn({
          shop_type: 1688,
          shop_id: JSON.stringify([this.details.shopId]),
        })
        .then((res) => {
          if (res.code != 0) return false;
          this.shopFavoriteStatus = res.data[0].inFavorite;
        });
    },
    //在库更新
    clearGoodsCache() {
      let arr = [];
      arr.push({
        goods_id: this.datas.goods_id,
        shop_type: this.datas.shop_type,
      });
      this.loading = this.$loading({
        lock: true,
        text: this.$fanyi("在库更新中"),
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
      this.$api.clearGoodsCache({ clear_data: arr }).then((res) => {
        this.skuProps = [];
        this.addCartSkuList = [];
        this.goodsInventoryOption = [];
        this.productDetails();
        this.loading.close();
      });
    },
    // 处理数据
    dataProcessing() {
      // sku处理
      let skuProps = [];
      let arr = [];
      let detail = [];
      this.goods_inventory = JSON.stringify(
        JSON.parse(JSON.stringify(this.details.goodsInfo.goodsInventory))
      );
      this.goodsInventoryStr = this.details.goodsInfo.goodsInventory;
      let goodsInventoryOptionStr = "";
      let pic = "";
      let value = "";
      this.details.goodsInfo.specification.forEach(
        (item, specificationIndex) => {
          item.valueC.forEach((items, index) => {
            if (
              index === 0 &&
              specificationIndex !==
                this.details.goodsInfo.specification.length - 1
            ) {
              item.active = 0;
              goodsInventoryOptionStr += items.name + "㊖㊎";
              if (items.picUrl !== "") {
                pic = items.picUrl;
              }
            }
            value = items.name;
            // 当轮到最后一项商品属性时处理并编成this.goodsInventoryOption
            if (
              specificationIndex ===
              this.details.goodsInfo.specification.length - 1
            ) {
              goodsInventoryOptionStr += `${items.name}`;
              for (let i = 0; i < this.goodsInfo.goodsInventory.length; i++) {
                if (
                  this.goodsInfo.goodsInventory[i].keyC ===
                  goodsInventoryOptionStr
                ) {
                  this.goodsInfo.goodsInventory[i].valueC[0].name = items.name;
                  this.goodsInfo.goodsInventory[i].valueC[0].nameT =
                    item.valueT[index].name; // 根据index索引从valueT数组获取翻译的名字并设置为nameT
                  if (items.picUrl !== "") {
                    this.goodsInfo.goodsInventory[i].valueC[0].picUrl =
                      items.picUrl;
                  }
                  if (pic !== "") {
                    this.goodsInfo.goodsInventory[i].valueC[0].newPicUrl = pic;
                  }
                  this.goodsInventoryOption.push(
                    this.$fun.deepClone(
                      this.goodsInfo.goodsInventory[i].valueC[0]
                    )
                  );
                  this.goodsInventoryPriceOption = this.$fun.deepClone(
                    this.goodsInfo.goodsInventory[i].valueC
                  );
                  break;
                }
              }
              goodsInventoryOptionStr = goodsInventoryOptionStr.substr(
                0,
                goodsInventoryOptionStr.length - items.name.length
              );
            }
          });
          detail.push({
            key: item.keyC,
            value: value,
          });
        }
      );
      this.goodsInventoryOption.forEach((item) => {
        item.num = 0;
      });
      this.showNum = this.goodsInventoryOption.length;
      skuProps = this.details.goodsInfo.specification;
      this.skuProps = skuProps;

      this.detailsImgIndex = 0;
      this.detailsImg = this.details.images[0];

      // });
    },
    dataProcessing2(res) {
      // 商家评分处理
      for (let i in res.data.sellerDataInfo) {
        res.data.sellerDataInfo[i] = Number(res.data.sellerDataInfo[i]);
      }
    },

    // 判断显示商家标签
    haveShopsTag(in_name) {
      let offerIdentities = this.details.offerIdentities;
      if (!offerIdentities) return false;
      if (offerIdentities.find((ii) => ii == in_name)) return true;
      return false;
    },

    // 清空加购物车的数据
    qingKong() {
      this.Count = 0;
      this.money = 0;
      this.goodsInventoryOption.forEach((item) => {
        item.num = 0;
      });
      this.$forceUpdate();
    },
    // 切换主图
    getPIc(pic, index) {
      this.detailsImgIndex = index;
      this.detailsImg = pic;
      this.showType = "img";
    },
    //   图组回到顶部
    gotop() {
      let timer = setInterval(function() {
        let osTop = document.querySelector(".images").scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.querySelector(".images").scrollTop = document.querySelector(
          ".images"
        ).scrollTop = osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
    //   图组到底部
    gobottom2() {
      let imgBox = document.querySelector(".images");
      imgBox.scrollTop = 1000;
    },
    // 为所有商品规格按照商品购买总数匹配价格
    //匹配价格
    piPeiJiaGe() {
      // 遍历this.goodsInventoryOption和this.addCartSkuList两个数组,将其中的所有价格都按照所有选择购买的总数匹配
      // console.log(this.goodsInventoryOption, this.addCartSkuList, this.Count);
      // console.log(this.details.goodsInfo.goodsInventory);
      // 先拿到当前选择的商品属性的拼接值(不包括最后一项)
      let qianStrArr = [];
      this.skuProps.forEach((PropsItem, PropsIndex) => {
        if (PropsIndex < this.skuProps.length - 1) {
          qianStrArr.push(PropsItem.valueC[PropsItem.active || 0].name);
        }
      });
      // addCartSkuList里面的数据不需要循环了,因为goodsInventoryOption里的数据匹配了价格之后会添加到addCartSkuList里面
      // this.addCartSkuList.forEach((skuListItem) => {
      //   // 如果addCartSkuList里的单条数据里面没有价格区间数据,就匹配一个
      //   if (!skuListItem.goodsInventory) {
      //     // 将skuListItem里的detail拼成字符串匹配价格区间
      //     let str = ""; //选项内容的匹配字符串
      //     let strArr = [];
      //     skuListItem.detail.forEach((detailItem) => {
      //       strArr.push(detailItem.value);
      //     });
      //     str = strArr.join("㊖㊎");
      //     // 从所有商品价格区间数组中找出拼接的字符串规格对应的价格区间
      //     let itemPriceRange = this.details.goodsInfo.goodsInventory.find(
      //       (rangeItem) => {
      //         console.log(str, rangeItem.valueC);
      //         return rangeItem.keyC == str || rangeItem.keyT == str;
      //       }
      //     );
      //     skuListItem.goodsInventory = this.$fun.deepClone(itemPriceRange);
      //   }
      //   // 从单条数据里的价格区间数据里面根据总购买数量匹配价格
      //   for (let i = skuListItem.goodsInventory.valueC.length - 1; i > 0; i--) {
      //     // console.log(skuListItem.goodsInventory.valueC[i]);
      //     if (
      //       this.Count >= skuListItem.goodsInventory.valueC[i].startQuantity
      //     ) {
      //       skuListItem.price = skuListItem.goodsInventory.valueC[i].price;
      //       break;
      //     }
      //   }
      // });
      this.goodsInventoryOption.forEach((goodsInvenItem) => {
        // 因为goodsInvenItem里面没有detail属性,所以需要拼接现在选择的商品属性值加上goodsInvenItem里面的name
        if (!goodsInvenItem.goodsInventory) {
          let strArr = this.$fun.deepClone(qianStrArr);
          strArr.push(goodsInvenItem.name);
          let str = strArr.join("㊖㊎");
          // 从所有商品价格区间数组中找出拼接的字符串规格对应的价格区间
          let itemPriceRange = this.details.goodsInfo.goodsInventory.find(
            (rangeItem) => {
              // console.log(str, rangeItem.valueC);
              return rangeItem.keyC == str || rangeItem.keyT == str;
            }
          );
          goodsInvenItem.goodsInventory = this.$fun.deepClone(itemPriceRange);
        }
        // 从单条数据里的价格区间数据里面根据总购买数量匹配价格
        for (
          let i = goodsInvenItem.goodsInventory.valueC.length - 1;
          i >= 0;
          i--
        ) {
          // console.log(
          //   goodsInvenItem.goodsInventory.valueC[i].startQuantity,
          //   this.Count,
          //   this.Count >= goodsInvenItem.goodsInventory.valueC[i].startQuantity
          // );
          // console.log(goodsInvenItem.goodsInventory.valueC[i]);
          if (
            this.Count >= goodsInvenItem.goodsInventory.valueC[i].startQuantity
          ) {
            goodsInvenItem.price = this.$fun.deepClone(
              goodsInvenItem.goodsInventory.valueC[i].price
            );
            break;
          }
        }
      });
      // console.log(this.goodsInventoryOption, this.Count);
    },
    // 颜色选择
    getColor(skuItem, index) {
      let goodsInventoryOption = this.$fun.deepClone(this.goodsInventoryOption);
      let skuProps = this.$fun.deepClone(this.skuProps);
      let detail = [];
      let valueName = "";
      skuProps.forEach((item, index) => {
        if (index === this.skuProps.length - 1) {
          for (let i = 0; i < goodsInventoryOption.length; i++) {
            if (goodsInventoryOption[i].num > 0) {
              valueName = goodsInventoryOption[i].name;
              if (detail.length > 0) {
                if (item.keyC === detail[detail.length - 1].key) {
                  detail.pop();
                }
              }

              detail.push({
                key: item.keyC,
                value:
                  index === this.skuProps.length - 1 ? valueName : valueName,
              });
              let img = "";
              if (this.goodsInventoryOption[i].picUrl == undefined) {
                if (this.goodsInventoryOption[i].newPicUrl !== "") {
                  img = this.goodsInventoryOption[i].newPicUrl;
                } else {
                  img = this.details.images[0];
                }
              } else {
                img = this.goodsInventoryOption[i].picUrl;
              }
              let findXUanXiang = this.addCartSkuList.find((item) => {
                return item.skuId == this.goodsInventoryOption[i].skuId;
              });
              if (findXUanXiang) {
                findXUanXiang.num = this.$fun.deepClone(
                  this.goodsInventoryOption[i].num
                );
              } else {
                this.addCartSkuList.push({
                  shop_id: this.details.shopId,
                  shop_name: this.wangwang,
                  goods_id: this.details.goodsId,
                  goods_title: this.details.titleC,
                  detail: JSON.parse(JSON.stringify(detail)),
                  from_platform: 1688,
                  num: this.$fun.deepClone(this.goodsInventoryOption[i].num),
                  price: this.$fun.deepClone(
                    this.goodsInventoryOption[i].price
                  ),
                  pic: img,
                  skuId: this.$fun.deepClone(
                    this.goodsInventoryOption[i].skuId
                  ),
                  spec_id: this.$fun.deepClone(
                    this.goodsInventoryOption[i].specId
                  ),
                });
              }
            }
          }
        } else {
          detail.push({
            key: item.keyC,
            value: item.valueC[item.active].name,
          });
        }
      });
      if (this.addCartSkuList > 0) {
        this.addCartSkuList = this.$fun.mergeArrayValue(this.addCartSkuList);
      }

      this.num = 1;
      skuItem.active = index;
      this.$forceUpdate();
      let str = "";
      let goodsInventoryOptionUrl = "";
      let goodsInventoryOptionStr = "";
      this.goodsInventoryOption = [];
      this.details.goodsInfo.specification.forEach(
        (item, specificationIndex) => {
          item.valueC.forEach((items, index) => {
            if (
              item.active === index &&
              specificationIndex !==
                this.details.goodsInfo.specification.length - 1
            ) {
              if (items.picUrl !== "") {
                goodsInventoryOptionUrl = items.picUrl;
              }
              goodsInventoryOptionStr += items.name + "㊖㊎";
            }
            if (
              specificationIndex ===
              this.details.goodsInfo.specification.length - 1
            ) {
              goodsInventoryOptionStr += `${items.name}`;
              for (let i = 0; i < this.goodsInfo.goodsInventory.length; i++) {
                if (
                  this.goodsInfo.goodsInventory[i].keyC ===
                  goodsInventoryOptionStr
                ) {
                  this.goodsInfo.goodsInventory[i].valueC[0].name = items.name;
                  this.goodsInfo.goodsInventory[i].valueC[0].nameT =
                    item.valueT[index].name; // 根据index索引从valueT数组获取翻译的名字并设置为nameT
                  if (items.picUrl !== "") {
                    this.goodsInfo.goodsInventory[i].valueC[0].picUrl =
                      items.picUrl;
                  } else if (
                    items.picUrl === "" &&
                    goodsInventoryOptionUrl !== ""
                  ) {
                    this.goodsInfo.goodsInventory[
                      i
                    ].valueC[0].newPicUrl = goodsInventoryOptionUrl;
                  }
                  this.goodsInventoryOption.push(
                    this.goodsInfo.goodsInventory[i].valueC[0]
                  );
                  this.goodsInventoryPriceOption = this.$fun.deepClone(
                    this.goodsInfo.goodsInventory[i].valueC
                  );
                  break;
                }
              }
              goodsInventoryOptionStr = goodsInventoryOptionStr.substr(
                0,
                goodsInventoryOptionStr.length - items.name.length
              );
            }
          });
        }
      );
      this.showNum = this.goodsInventoryOption.length;
      if (
        this.addCartSkuList.length > 0 &&
        this.goodsInventoryOption.length > 0
      ) {
        // 在addCartSkuList中查找skuId相同的选项,如果有就将购买数量赋予goodsInventoryOption中的选项,因为商品属性时会将编辑过的属性传到addCartSkuList里面
        this.goodsInventoryOption.forEach((item) => {
          for (let i = 0; i < this.addCartSkuList.length; i++) {
            if (item.skuId === this.addCartSkuList[i].skuId) {
              item.num = this.$fun.deepClone(this.addCartSkuList[i].num);
              break;
            } else {
              item.num = 0;
            }
          }
        });
      } else {
        this.goodsInventoryOption.forEach((goodsInventoryOptionItem) => {
          goodsInventoryOptionItem.num = 0;
        });
      }
      str = str.substr(0, str.length - 2);
      this.goodsStr = str;
      // 如果是有图片的选项就替换主图为选项图片
      if (skuItem.valueC[index].picUrl) {
        this.detailsImg = skuItem.valueC[index].picUrl;
        this.detailsImgIndex = -1;
        // this.getPrice(str);
        this.$forceUpdate();
      } else {
        // this.getPrice(str);
      }
      this.piPeiJiaGe();
    },
    // 查看大图
    getPic(pic) {
      this.detailsImg = pic;
      this.$forceUpdate();
    },
    // 判断购买数量是否大于在库数量
    //根据购买数量匹配价格
    maxNum(item, priceIndex) {
      // 限制数量不能为空
      if (item.num == undefined) {
        item.num = 1;
        this.$forceUpdate();
      }
      // 限制购买数量不能超过库存数
      if (item.num > item.amountOnSale) {
        this.$message.warning(this.$fanyi("购买数量不能超过在库数量"));
        return;
      }
      let count = 0;
      let money = 0;
      let skuIdList = [];
      let valueName = "";
      this.skuProps.forEach((skuPropsItem, index) => {
        if (index === this.skuProps.length - 1) {
          valueName += item.name;
        } else {
          valueName += skuPropsItem.valueC[skuPropsItem.active].name + "㊖㊎";
        }
      });
      // let price = 0;
      // this.details.goodsInfo.goodsInventory.forEach((specificationItem) => {
      //   if (specificationItem.keyC === valueName) {
      //     specificationItem.valueC.forEach((valueCItem) => {
      //       if (
      //         valueCItem.specId === item.specId &&
      //         (valueCItem.startQuantity === item.num ||
      //           item.num > valueCItem.startQuantity)
      //       ) {
      //         item.price = valueCItem.price;
      //         price = valueCItem.price;
      //       }
      //     });
      //   }
      // });
      this.goodsInventoryOption.forEach((items, itemsIndex) => {
        count += items.num;
        money += items.num * items.price;
        skuIdList.push(items.skuId);
      });
      let newCount = 0;
      let newMoney = 0;
      if (
        this.addCartSkuList.length > 0 &&
        this.addCartSkuList[0] != undefined
      ) {
        this.addCartSkuList.forEach((items) => {
          if (skuIdList.indexOf(items.skuId) === -1) {
            newCount += items.num;
            newMoney += items.num * items.price;
          }
        });
      }
      this.Count = newCount + count;
      this.money = newMoney + money;
      // 修改所有商品数据的价格
      // console.log(this.addCart);
      // console.log(this.goodsInventoryOption, this.skuProps);
      this.piPeiJiaGe();
      this.$forceUpdate();
    },

    // 将选中商品添加到购物车
    addCart() {
      // 判断是否登录
      if (!this.$store.state.userInfo) {
        return this.$store.commit(
          "getshowLoginDialog",
          !this.$store.state.showLoginDialog
        );
      }
      var detail = [];
      let toCartList = [];
      let valueName = "";
      this.skuProps.forEach((item, index) => {
        if (index === this.skuProps.length - 1) {
          for (let i = 0; i < this.goodsInventoryOption.length; i++) {
            if (this.goodsInventoryOption[i].num > 0) {
              valueName = this.goodsInventoryOption[i].name;
              if (detail.length > 0) {
                if (item.keyC === detail[detail.length - 1].key) {
                  detail.pop();
                }
              }
              detail.push({
                key: item.keyC,
                value:
                  index === this.skuProps.length - 1
                    ? valueName
                    : item.valueC[item.active].name,
              });

              let img = "";
              if (this.goodsInventoryOption[i].picUrl == undefined) {
                if (this.goodsInventoryOption[i].newPicUrl !== "") {
                  img = this.goodsInventoryOption[i].newPicUrl;
                } else {
                  img = this.details.images[0];
                }
              } else {
                img = this.goodsInventoryOption[i].picUrl;
              }
              this.addCartSkuList.push({
                shop_id: this.details.shopId,
                shop_name: this.wangwang,
                goods_id: this.details.goodsId,
                goods_title: this.details.titleC,
                detail: this.$fun.deepClone(detail),
                from_platform: this.$route.query.fromPlatform,
                num: this.goodsInventoryOption[i].num,
                price: this.goodsInventoryOption[i].price,
                pic: img,
                skuId: this.goodsInventoryOption[i].skuId,
                spec_id: this.$fun.deepClone(
                  this.goodsInventoryOption[i].specId
                ),
              });
              // console.log(detail);
              // console.log(this.addCartSkuList);
            }
          }
          this.addCartSkuList.forEach((item, index) => {
            if (item == undefined) {
              this.addCartSkuList.splice(index, 1);
            }
          });
          this.addCartSkuList = this.$fun.mergeArrayValue(this.addCartSkuList);
          this.addCartSkuList.forEach((item) => {
            if (item != undefined && item.num > 0) {
              let data = {
                sku_id: item.skuId,
                spec_id: item.spec_id,
                price: item.price,
                num: item.num,
                pic: item.pic != undefined ? item.pic : this.details.images[0],
                detailChinese: item.detail,
              };
              //// console.log(item.detail);
              toCartList.push(data);
            }
          });

          if (toCartList.length > 0) {
            let datas = {
              shop_id: this.details.shopId,
              shop_name: this.wangwang, //这里就是传旺旺名
              shop_name_translate: this.shopNameTrue,
              goods_id: this.details.goodsId,
              goods_title: this.details.titleT,
              goods_specification: JSON.stringify(toCartList),
              from_platform: 1688,
              priceRanges: JSON.stringify(this.goodsInfo.priceRanges),
            };
            // 为空则提示
            if (datas.goods_specification == null)
              return this.$alert(this.$fanyi("要继续，请将产品添加到购物车"));
            //   // type是true时代表多个一起添加
            this.isAddCart = true;
            // return console.log(datas);
            this.$api.goodsToCart(datas).then((res) => {
              if (res.code != 0)
                return this.$alert(this.$fanyi("购物车添加失败"));
              let img = document.querySelector("#addCart");
              img.src = require("../../assets/cart/cart.gif");
              img.style.display = "block";
              setTimeout(() => {
                img.src = "";
                img.style.display = "none";
                this.isAddCart = false;
              }, 3000);
              this.qingKong();
              this.$message.success(this.$fanyi("购物车添加成功"));
              this.$store.dispatch("goodsToCartNum");
            });
          }
        } else {
          detail.push({
            key: item.keyC,
            value: item.valueC[item.active].name,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../css/mixin";
// @import "./css/ProductDetails.scss";
#ProductDetails {
  .main {
    // overflow: hidden;
    margin-top: -20px;

    .contentBackground {
      width: 100%;
      background-color: white;
      padding-bottom: 0px;

      .content {
        background-color: white !important;

        .detailTop {
          margin-bottom: 70px;

          .detaiCon {
            .conRight {
              > h4 {
                font-size: 18px;
                font-weight: bold;
                color: #000000;
                margin-bottom: 35px;
              }

              > h5 {
                font-size: 16px;
                font-weight: 400;
                color: #ffa724;
                margin-bottom: 16px;
              }

              > div {
                width: 820px;
                margin: 0 0 20px auto;
                display: flex;
                // 商品分类内容div框
              }

              .conRightTitle {
                font-size: 16px;
                font-weight: 400;
                color: #010101;
                padding-left: 20px;
                width: 124px;

                &.priceGroupTitle {
                  p {
                    line-height: 60px;
                  }
                }
              }

              .priceGroup {
                width: 820px;
                height: 120px;
                background: #fbfbfb;
                display: flex;

                .priceCon {
                  display: flex;

                  .priceConOpt {
                    margin-right: 60px;

                    p {
                      line-height: 60px;
                      font-size: 14px;
                      font-weight: 400;
                      color: #ffa724;

                      .price {
                        font-size: 20px;
                      }

                      &:last-child {
                        color: #c07d1a;
                      }
                    }
                  }
                }
              }

              .shippingFee {
                align-items: center;
              }

              //   .numGroup {
              //     > div {
              //       display: flex;
              //       align-items: center;
              //     }
              //     > span {
              //       margin: 0px 20px;
              //       padding: 10px 0 0;
              //     }
              //   }
              .totalGroup {
                margin-bottom: 30px;

                .money {
                  > span {
                    font-size: 14px;
                    font-weight: 400;
                    color: #ffa724;

                    .price {
                      font-weight: bold;
                      font-size: 20px;
                    }
                  }
                }
              }

              @keyframes test {
                /* from表示动画的开始位置，也可以使用0%来表示。 */
                from {
                  transform: translate(-50px, -0px);
                  font-size: 50px;
                }

                /* to表示动画的结束位置，也可以使用100%来表示。 */
                to {
                  transform: translate(520px, -650px);
                  font-size: 16px;
                }
              }

              .addClassGroup {
                padding-left: 20px;
                padding-bottom: 20px;
                position: relative;
                > button {
                  width: 290px;
                  height: 60px;
                  background: #47bd9b;
                  border-radius: 5px;
                  font-size: 18px;
                  font-weight: 500;
                  color: #fff;

                  img {
                    display: none;
                    width: 100%;
                    height: 60px;
                  }

                  &:hover {
                    opacity: 0.7;
                  }
                }
              }
            }
          }
        }

        width: $pageWidth;
        margin: 2px auto 100px;
        padding-top: 30px;
        background-color: white;

        h3 {
          font-size: 14px;
          font-weight: 400;
          color: #000000;
          margin: 0px 0 30px;
        }

        .temporaryListing {
          padding-top: 20px;
          overflow: unset;
          border-top: solid 1px #eeeeee;
          margin-bottom: -40px;
          position: relative;

          .down {
            width: 0;
            height: 0;
            border-width: 11.25px 11.25px 0;
            border-style: solid;
            border-color: #999 transparent transparent;
            /*灰 透明 透明 */
            position: absolute;
            bottom: 79.25px;
            left: 90px;

            img {
              position: absolute;
              transform: rotate(180deg) !important;
              width: 42px;
              height: 23px;
              top: -14px;
              left: -21px;
            }
          }

          h2 {
            margin-bottom: 38px;
            font-size: 18px;
            font-weight: bold;
            color: #000000;
          }

          ul {
            display: flex;
            text-align: center;
            align-items: center;
            height: 35px;
            border-top: #eeeeee solid 1px;
            border-bottom: #eeeeee solid 1px;

            &:first-child {
              border: none;
            }

            li {
              flex: 0.57;
              display: inline-block;

              button {
                width: unset;
                height: unset;
                background-color: transparent;
                color: black;
                margin: 0;
                cursor: pointer;
              }

              &:nth-child(1) {
                img {
                  width: 40px;
                  height: 40px;
                }
              }

              &:nth-child(2) {
                flex: 3.15;
                display: flex;
              }

              &:nth-child(3) {
                // margin-left: 699px;
                text-overflow: ellipsis;
              }
            }

            &.tableHead {
              background: #f6f6f6;
            }

            &.tableBody {
              height: 60px;
              overflow: hidden;

              .color {
                display: flex;
                max-width: 734px;
                align-items: center;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis; //文本溢出显示省略号
                padding-left: 13px;

                span {
                  margin-right: 15px;
                  line-height: 18px;

                  &:last-child {
                    margin-right: 0;
                  }
                }
              }
            }
          }

          button {
            margin-top: 40px;
            width: 360px;
            height: 50px;
            background: #ffa724;
            border-radius: 5px;
            color: white;
          }
        }
      }
    }
  }

  .goodsMain {
    width: 1400px;
    margin: 0 auto;

    .nav {
      width: 1440px;
      height: 58px;
      margin: 0 auto 27px;
      .el-breadcrumb__item {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }
      .shopNameItem {
        /deep/.el-breadcrumb__inner {
          color: var(--Rakumart-500, #1a73e8);
          font-weight: 700;
        }
      }
    }
    .navSpan {
      display: inline-flex;
      max-width: 500px;
    }

    .goodsCon {
      display: flex;
      align-items: flex-start;
      margin-bottom: 80px;

      .kind {
        width: 650px;
        background: #ffffff;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        padding: 20px;

        .newKindCon {
          display: flex;
          flex-direction: column;
          align-items: top;
          position: relative;
          .controlButton {
            position: sticky;
            height: 62px;
            width: 12px;
            background-color: #e4e4e4;
            line-height: 62px;

            &:first-child {
              left: 0;
            }

            &:last-child {
              position: absolute;
              right: 0px;
            }
          }
          .ConBody {
            display: flex;
            overflow: auto;
            margin-top: 20px;
            margin-bottom: 15px;

            .choiceColor {
              color: #ffa724;
              border-color: #ffa724 !important;
            }

            > .sizeItem {
              display: flex;
              align-items: center;
              margin: 0 6px;
              background: #ffffff;
              border: 1px solid #cccccc;
              padding: 9px 15px;
              cursor: pointer;
              white-space: nowrap;

              img {
                width: 50px;
                height: 50px;
                margin: -4px 10px -4px -9px;
              }

              span {
                max-width: 200px;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }

          .allCheck {
            flex: 1;

            .allHead {
              display: flex;
              height: 40px;
              background-color: #dfdfdf;
            }

            .list {
              overflow: hidden;
              transition: all 0.3s;

              .item {
                display: flex;
                padding: 10px 0;
                border-bottom: 1px dashed #dfdfdf;
                height: 60px;
              }
            }

            img {
              width: 40px;
              //   height: 40px;
              margin-right: 10px;
              cursor: pointer;
            }

            .title {
              flex: 1.3;
              display: flex;
              align-items: center;
              margin-right: 10px;
              font-size: 14px;
            }

            .price {
              display: flex;
              justify-content: center;
              align-items: center;
              flex: 0.8;
            }

            .center {
              justify-content: center;
              text-align: center;
            }

            .maxLine {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            .numGroup {
              flex: 0.8;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .num {
              display: flex;
              justify-content: center;
              align-items: center;
              flex: 0.6;
            }

            .addShow {
              cursor: pointer;
              display: flex;
              justify-content: center;

              .showOption {
                width: 60px;
                height: 20px;
                background: #ffffff;
                border: 1px solid #dfdfdf;
                border-radius: 0 0 6px 6px;
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                  font-size: 18px;
                  color: #dfdfdf;
                }
              }
            }
          }
        }

        .kindCon {
          display: flex;
          flex-direction: column;
          align-items: top;
          position: relative;

          .conRightTitle {
            width: auto;
            font-size: 16px;
            font-weight: 600;
            display: block;
          }

          .ConBody {
            display: flex;
            overflow: auto;
            margin-top: 20px;
            margin-bottom: 15px;

            .choiceColor {
              color: #ffa724;
              border-color: #ffa724 !important;
            }

            .sizeItem {
              display: flex;
              align-items: center;
              margin: 0 6px;
              background: #ffffff;
              border: 1px solid #cccccc;
              padding: 9px 15px;
              cursor: pointer;
              white-space: nowrap;

              img {
                width: 50px;
                height: 50px;
                margin: -4px 10px -4px -9px;
              }

              span {
                max-width: 200px;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }

            .controlButton {
              position: sticky;
              height: 62px;
              width: 12px;
              background-color: #e4e4e4;
              line-height: 62px;

              &:first-child {
                left: 0;
              }

              &:last-child {
                position: absolute;
                right: 0px;
              }
            }
          }

          .allCheck {
            margin-top: 20px;
            font-size: 12px;

            .allHead {
              display: flex;
              height: 40px;
              font-size: 12px;
              background: #f0f1f7;
              font-weight: 600;
              border-radius: 3px;
            }

            .list {
              overflow: hidden;
              transition: all 0.3s;
            }

            .item {
              display: flex;
              padding: 10px 0;
              border-bottom: 1px solid #eeeeee;
              height: 63px;
            }

            img {
              width: 40px;
              //   height: 40px;
              margin-right: 10px;
              cursor: pointer;
            }

            .title {
              flex: 1.3;
              display: flex;
              justify-content: left;
              align-items: center;
              margin-right: 10px;
            }

            .price {
              display: flex;
              justify-content: center;
              align-items: center;
              flex: 1;
            }

            .center {
              justify-content: center;
              text-align: center;
            }

            .maxLine {
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            .numGroup {
              flex: 1;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .num {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0 5px;
              flex: 1;
            }

            .addShow {
              cursor: pointer;
              font-size: 12px;
              color: #1c2899;
              display: flex;
              justify-content: flex-end;
              align-items: center;

              img {
                margin-left: 6.72px;
                width: 15px;
                height: 15px;
              }
            }

            .top {
              margin-top: 15px;
            }
          }
        }
      }
    }

    .operatingParameter {
      margin-left: 15px;
    }

    .goodsDetail {
      padding: 60px;
      margin: 40px auto 100px;
      background-color: white;
      width: $pageWidth;

      h4 {
        font-size: 18px;
        font-weight: bold;
        color: #000000;
        border-left: 5px solid #ffa724;
        padding-left: 10px;
      }

      > .property {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;

        > p {
          width: 300px;
          margin-bottom: 20px;
          margin-right: 20px;

          &:last-child {
            margin: 0px;
          }

          > span {
            display: inline-block;
            width: 50%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            &:first-child {
              font-size: 12px;
              font-weight: 400;
              color: #999999;
              width: 48%;
            }

            &:last-child {
              font-size: 12px;
              font-weight: 400;
              color: #333333;
              margin-right: 2%;
            }
          }
        }
      }

      > .detail {
        margin-top: 100px;
        text-align: center;
        overflow: hidden;
      }
    }
  }

  .notHaveGoods {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 266px;
    background-color: white;
    width: 100%;

    .hintInfo {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      img {
        width: 480px;
        height: 480px;
        margin: 0 auto;
      }

      p {
        text-align: center;

        .backHomePage {
          padding: 10px 20px;
          margin-top: 40px;
          font-size: 16px;
          color: #ffffff;
          line-height: 18px;
          background: #1e2997;
          border-radius: 4px;
          cursor: pointer;
          transition: 0.3s;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
</style>
