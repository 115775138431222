<template>
  <div class="omain">
    <div class="numInfoBox">
      <div class="boxOpt">
        <p class="optTitle">{{ $fanyi("报价单位数量") }}:</p>
        <p class="optNum">{{ parentData.Count }}</p>
      </div>
      <div class="hr"></div>
      <div class="boxOpt">
        <p class="optTitle">{{ $fanyi("总价格") }}:</p>
        <p class="optNum">
          <span class="price"
            ><b
              >{{
                $fun.EURNumSegmentation(
                  isNaN(parentData.money)
                    ? ""
                    : $fun.ceil(parentData.money * $store.state.exchangeRate)
                )
              }}€</b
            >

            <span>
              ({{
                $fun.RMBNumSegmentation(
                  !isNaN(parentData.money) ? parentData.money.toFixed(2) : ""
                )
              }}￥)</span
            ></span
          >
        </p>
      </div>
      <div class="hr"></div>
      <button
        @click="parentData.addCart(false)"
        ref="start"
        class="start"
        id="start"
        :disabled="parentData.isAddCart"
      >
        <img alt="" id="addCart" />
        {{ $fanyi("加入购物车") }}
      </button>
    </div>
    <div class="otherInformation">
      <div class="promptText">
        {{ $fanyi("如果您想购买和发送此产品的样品，请联系专家代理。") }}
      </div>
      <button class="contactUs" @click="$fun.clickJs()">
        {{ $fanyi("与代理交谈") }}
      </button>
      <div class="hr"></div>
      <div class="piFa">
        <img :src="require('@/assets/icon/lansetishi.png')" alt="" />
        <span>
          {{ $fanyi("Rakumart是一个批发进口平台，不提供零售服务。") }}</span
        >
      </div>
      <div class="hr"></div>
      <div class="downGoodsImgBox flex fdcolmun">
        <div class="downBoxTit">
          {{ $fanyi("下载图片") }}
        </div>
        <div class="downBoxCon">{{ $fanyi("下载所有的产品图片") }}</div>
        <el-button
          type="primary"
          class="bgBlueBtn downBoxBtn "
          @click="downLoadAllPic"
          >{{ $fanyi("下载") }}
          <img
            src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/6685038fdbf62.svg"
            alt=""
          />
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import JSZip from "jszip";
import FileSaver from "file-saver"; //saveAs用到了这个引入

export default {
  data() {
    return {};
  },
  components: {},
  computed: {
    parentData() {
      return this.$parent;
    },
  },
  created() {},
  methods: {
    downLoadAllPic() {
      let box = document.getElementById("introductionOfGoods");
      let dom = document.createElement("div");
      dom.innerHTML = JSON.parse(
        JSON.stringify(this.$parent.details.description)
      );
      let imgArr = dom.getElementsByTagName("img");
      let srcarr = [];
      for (let i = 0; i < imgArr.length; i++) {
        srcarr.push(imgArr[i].src);
      }
      console.log(imgArr, srcarr);
      this.fileArray = [...this.$parent.details.images, ...srcarr];

      (async () => {
        // 初始化一个zip打包对象
        const zip = new JSZip();

        // 创建一个名为录音文件的新的文件目录
        const folder = zip.folder(
          `Productos ${this.$route.query.goods_id}.zip`
        );
        for (let i = 0; i < this.fileArray.length; i++) {
          const blob = await fetch(this.fileArray[i]).then((response) =>
            response.blob()
          );

          // 文件夹添加资源，图片也支持base64类型 {base64: true}
          folder.file(
            this.fileArray[i].substring(this.fileArray[i].lastIndexOf("/") + 1),
            blob,
            { Blob: true }
          );
        }
        // 请求远程资源的blob，其他文件，比如视频、表格等也是一样的

        // 把打包内容异步转成blob二进制格式
        zip.generateAsync({ type: "blob" }).then((content) => {
          // 下载压缩包
          saveAs(content, `Productos ${this.$route.query.goods_id}.zip`);
        });
      })();
    },

    chat(chat) {
      if (this.$store.state.userInfo) {
        let iTop = (window.screen.availHeight - 30 - 700) / 2;
        //获得窗口的水平位置
        let iLeft = (window.screen.availWidth - 10 - 1260) / 2;
        if (chat == 1) {
          window.open(
            "https://rakuchat1.rakumart.cn/im/dist/#/?id=" +
              this.$store.state.userInfo.user_id,
            this.$store.state.userInfo,
            "height=700, width=1260, left=" +
              iLeft +
              ", top=" +
              iTop +
              ", toolbar=no, menubar=no, scrollbars=yes, resizable=no, location=no, status=no"
          );
        } else {
          window.open(
            this.chatLink ? this.chatLink : "HTTPS://wa.me/+34621188939",
            "WhatsApp",
            "height=700, width=1260, left=" +
              iLeft +
              ", top=" +
              iTop +
              ", toolbar=no, menubar=no, scrollbars=yes, resizable=yes, location=no, status=no"
          );
        }
      } else {
        this.$store.commit(
          "getshowLoginDialog",
          !this.$store.state.showLoginDialog
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.omain {
  .numInfoBox {
    width: 250px;
    background: #ffffff;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    padding: 0px 20px 20px;
    .hr {
      background-color: #6d6d6d;
      height: 0.4px;
    }
    .boxOpt {
      text-align: right;
      height: 75px;

      display: flex;
      justify-content: center;
      align-items: flex-end;
      flex-direction: column;
      .optTitle {
        color: #747474;
        font-size: 12px;
        margin-bottom: 5px;
      }
      .optNum {
        font-size: 15px;
        font-weight: 500;
        .price {
          font-size: 14px;
          color: #f1a53d;
          b {
            font-size: 20px;
          }
        }
      }
    }
  }
  .otherInformation {
    width: 233px;
    margin-left: auto;
    .promptText {
      font-size: 12px;
      margin-top: 14px;
    }
    .contactUs {
      margin-top: 12px;
      width: 224px;
      height: 41px;
      border: 1px solid #3171e8;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      color: #3171e8;
      transition: 0.3s;
      overflow: hidden;
      &:hover {
        background-color: #3171e8;
        color: white;
      }
      img {
        display: none;
        width: 100%;
        height: 41px;
      }
    }
    .hr {
      background-color: #929191;
      height: 0.5px;
      width: 100%;
      margin: 20px 0;
    }
    .piFa {
      display: flex;
      align-items: flex-start;
      img {
        width: 21px;
        height: 21px;
        margin-right: 5px;
      }
      span {
        font-size: 12px;
      }
    }
  }
}
.start {
  width: 211px;
  height: 50px;
  background: #47bd9b;
  border-radius: 3px;
  font-size: 15px;
  font-weight: 600;
  display: block;
  margin-top: 20px;
  color: #fff;
  overflow: hidden;
  img {
    display: none;
    width: 100%;
    height: 100%;
  }
  &:hover {
    opacity: 0.7;
  }
}
.downGoodsImgBox {
  min-height: 126px;
  padding: 12px 16px;
  background-color: white;
  border-radius: 8px;
  font-size: 14px;
  .downBoxTit {
    font-weight: 700;
    line-height: 20px; /* 142.857% */
  }
  .downBoxCon {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px; /* 150% */
  }
  .downBoxBtn {
    width: 100%;
    height: 36px;
    line-height: 1;
    margin-top: auto;
    padding: 0;
    /deep/ span {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      img {
        margin-left: 4px;
      }
    }
  }
}
</style>
