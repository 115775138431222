<template>
  <div class="shopDetailBox">
    <div class="detailHeadBox flex acenter jsb">
      <div class="shopName flex acenter">
        <span class="title"> {{ shopNameTrue }}</span>
        <div class="sellerTagsBar flex">
          <!-- 实力商家 -->
          <div class="iconBox blue" v-if="haveShopsTag('powerful_merchants')">
            <img :src="require('@/assets/icon/goods1.svg')" alt="" />
            <span>{{ $fanyi("实力商家") }}</span>
          </div>
          <!-- 超级工厂 -->
          <div class="iconBox red" v-if="haveShopsTag('super_factory')">
            <img :src="require('@/assets/icon/goods2.svg')" alt="" />
            <span>{{ $fanyi("超级工厂") }}</span>
          </div>
        </div>
      </div>
      <div class="headBtnBox">
        <el-button
          class="fontBlueBtn"
          @click="
            $fun.routerToPage(
              `/shopGoods?shopName=${shopNameTrue}&shopId=${details.shopId}&goodsName=${details.titleT}`,
              true
            )
          "
          >{{ $fanyi("看到商店") }}</el-button
        >
        <el-button
          v-if="!shopFavoriteStatus"
          type="primary"
          class="bgBlueBtn"
          @click="shopAddFavorite"
          >{{ $fanyi("收藏店铺") }}</el-button
        >
        <el-button class="fontBlueBtn" v-else @click="favoriteShopDelete">{{
          $fanyi("取消追踪")
        }}</el-button>
      </div>
    </div>

    <div class="rateBox">
      <div class="rateContainerHead flex acenter">
        <div class="text">{{ $fanyi("估价gobal") }}</div>
        <el-popover
          popper-class="rateBoxPopover"
          placement="bottom"
          width="309"
          trigger="hover"
          @show="hoverTip = true"
          @hide="hoverTip = false"
        >
          <div class="popoverRateBox">
            <div class="rateContainer">
              <div class="text">{{ $fanyi("质量评分") }}</div>
              <el-rate
                v-model="details.sellerDataInfo.offerExperienceScore"
                disabled
                text-color="#ff9900"
              >
              </el-rate>
            </div>
            <div class="rateContainer">
              <div class="text">{{ $fanyi("物流评分") }}</div>
              <el-rate
                v-model="details.sellerDataInfo.logisticsExperienceScore"
                disabled
                text-color="#ff9900"
              >
              </el-rate>
            </div>
            <div class="rateContainer">
              <div class="text">{{ $fanyi("问题解决评分") }}</div>
              <el-rate
                v-model="details.sellerDataInfo.disputeComplaintScore"
                disabled
                text-color="#ff9900"
              >
              </el-rate>
            </div>
            <div class="rateContainer">
              <div class="text">{{ $fanyi("咨询评分") }}</div>
              <el-rate
                v-model="details.sellerDataInfo.consultingExperienceScore"
                disabled
                text-color="#ff9900"
              >
              </el-rate>
            </div>
          </div>
          <div slot="reference" class="flex acenter">
            <el-rate
              v-model="details.sellerDataInfo.compositeServiceScore"
              disabled
              text-color="#ff9900"
            >
            </el-rate>
            <img
              class="rateIcon"
              :class="{ hoverIn: hoverTip }"
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/6683797671ecb.svg"
              alt=""
            />
          </div>
        </el-popover>
      </div>
    </div>

    <div class="recommendSlide SearchConterCrosswise_public" v-if="shopDetail">
      <el-carousel trigger="click" height="300px">
        <el-carousel-item
          v-for="(item, index) in shopDetail.goods"
          :key="index"
          :autoplay="false"
          style="background-color: #000000"
        >
          <div class="recommendSlideCon">
            <div
              class="SearchConterCrosswiseCon"
              v-for="(otem, ondex) in item"
              :key="ondex"
              @click="$fun.toCommodityDetails(otem.goods_id)"
            >
              <div class="SearchConterCrosswiseImg">
                <img :src="otem.imgUrl" alt="" class="el-image" />
              </div>

              <div class="rests">
                <p class="goodsTitle">{{ otem.title }}</p>
                <p class="price">
                  <span
                    >{{
                      $fun.EURNumSegmentation(
                        $fun.ceil(otem.goodsPrice * $store.state.exchangeRate)
                      )
                    }}€</span
                  >
                  {{ $fun.RMBNumSegmentation(otem.goodsPrice) }}￥
                </p>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      hoverTip: false, //控制星级评分箭头转动
    };
  },
  components: {},
  computed: {
    shopNameTrue() {
      return this.$parent.shopNameTrue;
    },
    details() {
      return this.$parent.details;
    },
    wangwang() {
      return this.$parent.wangwang;
    },
    shopFavoriteStatus() {
      return this.$parent.shopFavoriteStatus;
    },
    shopDetail() {
      return this.$parent.shopDetail;
    },
  },
  created() {},
  methods: {
    // 判断显示商家标签
    haveShopsTag(in_name) {
      let offerIdentities = this.details.offerIdentities;
      if (!offerIdentities) return false;
      if (offerIdentities.find((ii) => ii == in_name)) return true;
      return false;
    },
    // 店铺收藏移除
    favoriteShopDelete() {
      this.$api
        .favoriteShopDelete({
          shop_type: 1688,
          shop_name: this.shopNameTrue,
          shop_id: this.details.shopId,
          wangwang: this.wangwang,
        })
        .then((res) => {
          if (res.code != 0) return false;
          this.$parent.shopFavoriteStatus = !this.$parent.shopFavoriteStatus;
          this.$forceUpdate();
        });
    },
    // 将店铺加入收藏夹
    shopAddFavorite() {
      if (!this.$store.state.userInfo) {
        return this.$store.commit(
          "getshowLoginDialog",
          !this.$store.state.showLoginDialog
        );
      }
      this.$api
        .favoriteShopAdd({
          shop_type: 1688,
          shop_name: this.shopNameTrue,
          shop_id: this.details.shopId,
          wangwang: this.wangwang,
        })
        .then((res) => {
          if (res.code != 0) return false;
          this.$parent.shopFavoriteStatus = !this.$parent.shopFavoriteStatus;
          this.$forceUpdate();
        });
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.shopDetailBox {
  width: 1402px;
  // height: 397px;
  border-radius: 24px;
  background: var(--Base-White, #fff);
  padding: 32px 0 0;
  overflow: hidden;
  .detailHeadBox {
    padding: 0 32px;
    .shopName {
      color: var(--Gray-700, #344054);
      font-size: 24px;
      font-weight: 700;
      margin-right: 16px;
      .title {
        display: inline-block;
        max-width: 650px;
        @extend .text-overflow-one;
      }
    }

    .iconBox {
      display: flex;
      align-items: center;
      padding: 2px 6px;
      border: solid 1px;
      border-radius: 6px;
      margin-right: 16px;
      img {
        width: 12px;
        margin-right: 4px;
      }
      span {
        font-size: 12px;
        font-weight: 600;
        line-height: 22px;
      }
    }
    .iconBox.red {
      color: #b93815;
      border-color: #f9dbaf;
      background: #fef6ee;
    }
    .iconBox.blue {
      color: #5925dc;
      border-color: #5925dc;
      background: #f4f3ff;
    }
    .sellerTagsBar {
      margin-top: 4px;
      margin-left: 16px;
    }

    .headBtnBox {
      .el-button {
        border-radius: 8px;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }

  .rateBox {
    padding: 0 32px;
    .rateContainerHead {
      width: max-content;
      cursor: pointer;
      .text {
        margin-right: 15px;
      }
      .rateIcon {
        transition: 0.3s;
        rotate: 180deg;
      }
      .rateIcon.hoverIn {
        rotate: 0deg;
      }
    }
  }
  .recommendSlide {
    width: 100%;
    flex: 1;
    margin: 0 auto;

    .recommendSlideCon {
      padding: 0 32px;
      padding-top: 48px;
      padding-bottom: 24px;
      background-color: white;
      height: 100%;
      display: flex;
      align-items: center;
      // padding-top: 30px;

      display: flex;
      gap: 24px;
      .SearchConterCrosswiseCon {
        $size: 168px;
        width: $size;
        height: 231px;
        overflow: hidden;
        margin: 0;
        border: none;
        box-shadow: none;
        &:hover {
          box-shadow: 0px 10px 20px #d1d1d1;
        }
        .SearchConterCrosswiseImg {
          width: $size;
          height: $size;
          .el-image {
            width: $size;
            height: $size;
          }
        }
        .rests {
          padding: 8px;
        }
      }
    }
  }
}

/deep/.el-rate__icon {
  font-size: 24px;
  margin: -2px -3px; //模拟西班牙设计图星星的大小
  margin-right: 4px;
}
.popoverRateBox {
  padding: 12px;
  .rateContainer {
    margin-bottom: 8px;
    .text {
      color: var(--Gray-700, #344054);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      display: flex;
      align-items: center;
      height: 20px;
    }
  }
}
</style>
