<template>
  <div class="conLeft">
    <!-- 图片说明 -->
    <div class="fl image clearfix clearfloat">
      <!-- 数据请求回来显示 -->
      <div class="pic-box fl">
        <pic-zoom
          v-if="$parent.showType == 'img'"
          :url="detailsImg"
          :scale="3"
        ></pic-zoom>
        <video
          class="bigVideo"
          muted
          autoplay
          v-else
          controls
          loop
          @click="$parent.lookVideo"
        >
          <source :src="details.video" type="video/mp4" />
          <source :src="details.video" type="video/ogg" />
          您的浏览器不支持 video 标签。
        </video>
      </div>
      <div class="images-box fl">
        <div class="go" @mouseover="gotop">
          <div class="icon">
            <i class="el-icon-arrow-up"></i>
          </div>
        </div>
        <div class="images">
          <div class="video" v-if="details.video" @click="$parent.lookVideo()">
            <div class="videoIcon">
              <!-- <i class="el-icon-video-play"></i> -->
              <svg
                t="1678259841461"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="2732"
                width="40"
                height="40"
              >
                <path
                  d="M512 0C230.4 0 0 230.4 0 512s230.4 512 512 512 512-230.4 512-512S793.6 0 512 0z m0 981.333333C253.866667 981.333333 42.666667 770.133333 42.666667 512S253.866667 42.666667 512 42.666667s469.333333 211.2 469.333333 469.333333-211.2 469.333333-469.333333 469.333333z"
                  fill="#ffffff"
                  p-id="2733"
                ></path>
                <path
                  d="M672 441.6l-170.666667-113.066667c-57.6-38.4-106.666667-12.8-106.666666 57.6v256c0 70.4 46.933333 96 106.666666 57.6l170.666667-113.066666c57.6-42.666667 57.6-106.666667 0-145.066667z"
                  fill="#ffffff"
                  p-id="2734"
                ></path>
              </svg>
            </div>
            <video>
              <source :src="details.video" type="video/mp4" />
              <source :src="details.video" type="video/ogg" />
              您的浏览器不支持 video 标签。
            </video>
          </div>
          <div class="img" v-for="(item, index) in details.images" :key="item">
            <img
              :src="item"
              alt=""
              @click="parentData.getPIc(item, index)"
              :class="{
                detailsImgIndex: parentData.detailsImgIndex == index,
              }"
            />
          </div>
        </div>
        <div class="go" @mouseover="gobottom2">
          <div class="icon">
            <i class="el-icon-arrow-down"></i>
          </div>
        </div>
      </div>
      <div class="huoyuan">
        <a href="javascript:;" @click="favoriteGoodsAdd()">
          <img
            v-if="parentData.goodsFavoriteStatus"
            :src="require('@/assets/SearchResultsPage/Collected.png')"
            alt=""
          />
          <img
            v-else
            :src="require('@/assets/SearchResultsPage/collection.png')"
            alt=""
          />
          {{ $fanyi("收藏") }}
        </a>
      </div>
    </div>
    <div></div>
  </div>
</template>
<script>
import PicZoom from "vue-piczoom";
export default {
  data() {
    return {};
  },
  components: {
    PicZoom,
  },
  computed: {
    detailsImg() {
      return this.$parent.detailsImg;
    },
    details() {
      return this.$parent.details;
    },

    parentData() {
      return this.$parent;
    },
  },
  created() {},
  methods: {
    //   图组回到顶部
    gotop() {
      let timer = setInterval(function() {
        let osTop = document.querySelector(".images").scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.querySelector(".images").scrollTop = document.querySelector(
          ".images"
        ).scrollTop = osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
    //   图组到底部
    gobottom2() {
      let imgBox = document.querySelector(".images");
      imgBox.scrollTop = 1000;
    },
    // 添加收藏商品
    favoriteGoodsAdd() {
      if (!this.$store.state.userInfo) {
        return this.$store.commit(
          "getshowLoginDialog",
          !this.$store.state.showLoginDialog
        );
      }
      let addPrice = 0;
      if (this.details.goodsInfo.priceRanges) {
        if (this.details.goodsInfo.priceRanges[0].priceMin) {
          addPrice = this.details.goodsInfo.priceRanges[0].priceMin;
        } else {
          addPrice = this.details.goodsInfo.priceRanges[0].price;
        }
      }
      let datas = {
        goods_id: this.details.goodsId,
        title: this.details.titleT,
        image_url: this.details.images[0],
        price: addPrice,
      };
      if (!this.$parent.goodsFavoriteStatus) {
        this.$api.favoriteGoodsAdd(datas).then((res) => {
          if (res.code != 0) return;
          this.$parent.goodsFavoriteStatus = !this.$parent.goodsFavoriteStatus;
          this.$forceUpdate();
        });
      } else {
        this.$api
          .favoriteGoodsDelete({
            goods_id: this.details.goodsId,
            // id: "[]",
          })
          .then((res) => {
            if (res.code != 0) return;
            this.$parent.goodsFavoriteStatus = !this.$parent
              .goodsFavoriteStatus;
            this.$forceUpdate();
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.conLeft {
  margin-right: 20px;
}

.image {
  .pic-box {
    width: 375px;
    height: 375px;
    display: inline-block;
    margin-right: 7.68px;

    img {
      width: 375px;
      height: 375px;
    }

    .bigVideo {
      width: 375px;
      height: 375px;
    }
  }

  .images-box {
    display: inline-block;

    height: 375px;

    .go {
      width: 74.4px;
      cursor: pointer;
      height: 12px;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #dedede;

        i {
          font-size: 12px;
          color: #000;
        }
      }
    }

    .images {
      overflow: hidden;
      height: 350px;

      .video {
        cursor: pointer;
        position: relative;
        margin-bottom: 15px;

        .videoIcon {
          position: absolute;
          background-color: rgba($color: #000000, $alpha: 0.3);
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          font-size: 40px;

          .icon {
            color: white;
          }
        }

        video {
          width: 74.4px;
          height: 74.4px;
        }
      }

      .img {
        img {
          width: 74.4px;
          height: 74.4px;
          cursor: pointer;
          margin-bottom: 6.24px;
          border: 1px solid transparent;
        }

        .detailsImgIndex {
          border: 1px solid #ffa724;
        }
      }
    }
  }

  .huoyuan {
    margin: 420px 0 20px;

    a {
      color: #333;
      margin-right: 40px;
      display: flex;
      align-items: center;

      &:hover {
        color: #999;
      }
    }

    img {
      height: 18px;
      vertical-align: middle;
      margin-right: 5px;
    }

    p {
      margin: 10px 0;

      a {
        margin-right: 10px;
      }
    }
  }

  .text {
    width: 805px;

    h4 {
      font-size: 18px;

      font-weight: bold;
      color: #000000;
      margin-bottom: 35px;
    }

    .p {
      font-size: 16px;

      font-weight: 400;
      color: #ffa724;
      margin-bottom: 16px;
    }

    p {
      font-size: 14px;
    }

    .price-box {
      width: 100%;
      height: 120px;
      background: #fbfbfb;
      display: flex;
      box-sizing: border-box;
      padding: 30px 20px;
      margin-bottom: 20px;

      > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 150px;

        &:first-child {
          width: unset;

          > * {
            font-size: 16px;

            font-weight: 400;
            color: #010101;
            margin-right: 30px;
          }
        }

        > * {
          font-size: 14px;

          font-weight: 400;
          color: #ffa724;
        }

        .price {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }

    .freightCharge {
      height: 16px;
      font-size: 16px;

      font-weight: 400;
      color: #000000;
      margin: 20px;
    }

    .addClassGroup {
      position: relative;
      top: 35px;
      margin-left: 20px;
      margin-top: -15px;

      button {
        width: 200px;
        height: 50px;
        background: #ffa724;
        border-radius: 5px;
        font-size: 18px;

        font-weight: 400;
        color: #ffffff;
        margin-right: 10px;

        &:last-child {
          background: #ffffff;
          border: 1px solid #ffa724;
          border-radius: 5px;
          font-size: 18px;

          font-weight: 400;
          color: #ffa724;
        }
      }
    }
  }
}
</style>
