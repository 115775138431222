<template>
  <div class="omain">
    <h4>
      {{ details.titleT }}
    </h4>
    <!-- 价格区间 -->
    <div class="priceGroup" v-if="details.goodsInfo.priceRanges">
      <div class="priceCon" v-if="details.goodsInfo.priceRanges[0].priceMin">
        <div
          class="priceConOpt"
          v-for="(priitem, priindex) in details.goodsInfo.priceRanges"
          :key="priindex"
        >
          <p class="priceBox">
            <b class="price"
              >{{
                $fun.EURNumSegmentation(
                  $fun.ceil(priitem.priceMin * $store.state.exchangeRate)
                )
              }}€ </b
            >(
            <span class="price">{{
              $fun.RMBNumSegmentation(priitem.priceMin)
            }}</span
            >￥)
            <span v-show="priitem.priceMax != priitem.priceMin"
              >~
              <b class="price"
                >{{
                  $fun.EURNumSegmentation(
                    $fun.ceil(priitem.priceMax * $store.state.exchangeRate)
                  )
                }}€ </b
              >(
              <span class="price">{{
                $fun.RMBNumSegmentation(priitem.priceMax)
              }}</span
              >￥)</span
            >
          </p>
          <p class="startingBatch">
            {{ $fanyi("从") }}
            {{ priitem.startQuantity }}
            {{ $fanyi("起") }}
          </p>
        </div>
      </div>
      <div class="priceCon" v-else>
        <div
          class="priceConOpt"
          v-for="(ote, oin) in goodsInfo.priceRanges"
          :key="oin"
        >
          <p class="priceBox">
            <b class="price"
              >{{
                $fun.RMBNumSegmentation(
                  $fun.ceil(ote.price * $store.state.exchangeRate)
                )
              }}€
            </b>

            <span class="price">
              ({{ $fun.EURNumSegmentation(ote.price) }}￥)</span
            >
          </p>
          <p class="startingBatch">
            {{ $fanyi("从") }} {{ ote.startQuantity }} {{ $fanyi("起") }}
          </p>
        </div>
      </div>
    </div>
    <h5>
      <img :src="require('@/assets/icon/tishi.png')" alt="" />
      {{ $fanyi("如地段价格显示不正确，报价完成后会更正。") }}
    </h5>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
  computed: {
    details() {
      return this.$parent.details;
    },
    goodsInfo() {
      return this.$parent.goodsInfo;
    },
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.omain {
  width: 652.8px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 15px;
  h4 {
    font-size: 15.84px;
    line-height: 22px;
    margin-bottom: 24px;
  }
  .priceGroup {
    .priceCon {
      display: flex;
      .priceConOpt {
        margin-right: 48px;
        .priceBox {
          font-size: 22px;
          color: #f1a53d;
          margin-bottom: 7px;
        }

        .startingBatch {
          color: #acacac;
          font-size: 15px;
        }
      }
    }
  }
  h5 {
    margin-top: 24px;
    display: flex;
    align-items: center;
    font-size: 12px;
    img {
      width: 25px;
      height: 25px;
      margin-right: 5px;
    }
  }
}
</style>
